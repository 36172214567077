// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Term_main_div__ID6xG{
    max-width: 1300px;
    margin-inline: auto;
    text-align: left;
    padding-block: 50px;
    line-height: 2;
    & h4{
        font-weight: 600;
        font-family: "Inter", sans-serif;
    }
    & h5{
        font-size: 18px;
        font-weight: 600;
        font-family: "Inter", sans-serif;
        text-align: left;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    & p{
        font-size: 16px;
        font-weight: 500;
        font-family: "Inter", sans-serif;
        text-align: left;
        color: #5E6075;
     
    }
}
strong{
    font-weight: 600;
    color: black;
}
.Term_inn_div__koBiG{
    text-align: left;
    margin-bottom: 30px;
    display: flex;
    gap: 10px;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    cursor: pointer;
    & span{
        font-size: 16px;
    }
}
@media screen and (max-width : 1400px) {
   .Term_main_div__ID6xG{
    padding-inline: 100px;
    max-width: auto;
   } 
}
@media screen and (max-width : 620px) {
.Term_main_div__ID6xG{
    padding: 30px;
}
}`, "",{"version":3,"sources":["webpack://./src/Pages/FooterPages/Term.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;IACd;QACI,gBAAgB;QAChB,gCAAgC;IACpC;IACA;QACI,eAAe;QACf,gBAAgB;QAChB,gCAAgC;QAChC,gBAAgB;QAChB,gBAAgB;QAChB,mBAAmB;IACvB;IACA;QACI,eAAe;QACf,gBAAgB;QAChB,gCAAgC;QAChC,gBAAgB;QAChB,cAAc;;IAElB;AACJ;AACA;IACI,gBAAgB;IAChB,YAAY;AAChB;AACA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,gCAAgC;IAChC,gBAAgB;IAChB,eAAe;IACf;QACI,eAAe;IACnB;AACJ;AACA;GACG;IACC,qBAAqB;IACrB,eAAe;GAChB;AACH;AACA;AACA;IACI,aAAa;AACjB;AACA","sourcesContent":[".main_div{\n    max-width: 1300px;\n    margin-inline: auto;\n    text-align: left;\n    padding-block: 50px;\n    line-height: 2;\n    & h4{\n        font-weight: 600;\n        font-family: \"Inter\", sans-serif;\n    }\n    & h5{\n        font-size: 18px;\n        font-weight: 600;\n        font-family: \"Inter\", sans-serif;\n        text-align: left;\n        margin-top: 30px;\n        margin-bottom: 20px;\n    }\n    & p{\n        font-size: 16px;\n        font-weight: 500;\n        font-family: \"Inter\", sans-serif;\n        text-align: left;\n        color: #5E6075;\n     \n    }\n}\nstrong{\n    font-weight: 600;\n    color: black;\n}\n.inn_div{\n    text-align: left;\n    margin-bottom: 30px;\n    display: flex;\n    gap: 10px;\n    align-items: center;\n    font-family: \"Inter\", sans-serif;\n    font-weight: 600;\n    cursor: pointer;\n    & span{\n        font-size: 16px;\n    }\n}\n@media screen and (max-width : 1400px) {\n   .main_div{\n    padding-inline: 100px;\n    max-width: auto;\n   } \n}\n@media screen and (max-width : 620px) {\n.main_div{\n    padding: 30px;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main_div": `Term_main_div__ID6xG`,
	"inn_div": `Term_inn_div__koBiG`
};
export default ___CSS_LOADER_EXPORT___;
