// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Landing_main_div__c-xnQ{
    max-width: 1300px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    margin-inline: auto;
}

.Landing_float__683IP{
	position:fixed;
	width:60px;
	height:60px;
	bottom:30px;
	right:30px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
   display: none;
}
.Landing_float__683IP > svg{
    margin-top: 10px;
}

@media screen and (max-width : 620px) {
    .Landing_main_div__c-xnQ{
        padding: 30px;
        padding-top: 10px;
    }
    .Landing_float__683IP{
        display: block;
    }
 }`, "",{"version":3,"sources":["webpack://./src/Pages/Landing.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,yBAAyB;IACzB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;CACC,cAAc;CACd,UAAU;CACV,WAAW;CACX,WAAW;CACX,UAAU;CACV,wBAAwB;CACxB,UAAU;CACV,kBAAkB;CAClB,iBAAiB;EAChB,cAAc;CACf,4BAA4B;EAC3B,WAAW;GACV,aAAa;AAChB;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,aAAa;QACb,iBAAiB;IACrB;IACA;QACI,cAAc;IAClB;CACH","sourcesContent":[".main_div{\n    max-width: 1300px;\n    display: flex;\n    flex-direction: column;\n    /* align-items: center; */\n    justify-content: center;\n    margin-inline: auto;\n}\n\n.float{\n\tposition:fixed;\n\twidth:60px;\n\theight:60px;\n\tbottom:30px;\n\tright:30px;\n\tbackground-color:#25d366;\n\tcolor:#FFF;\n\tborder-radius:50px;\n\ttext-align:center;\n  font-size:30px;\n\tbox-shadow: 2px 2px 3px #999;\n  z-index:100;\n   display: none;\n}\n.float > svg{\n    margin-top: 10px;\n}\n\n@media screen and (max-width : 620px) {\n    .main_div{\n        padding: 30px;\n        padding-top: 10px;\n    }\n    .float{\n        display: block;\n    }\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main_div": `Landing_main_div__c-xnQ`,
	"float": `Landing_float__683IP`
};
export default ___CSS_LOADER_EXPORT___;
