// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

*{margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
text-align: center;
z-index: 1;
  /* padding: 0 90px; */
  /* max-width: 1500px; */
}

.btn:hover,
.btn:focus-visible {
  color: inherit !important;
  border: none !important;
  background: none !important;
  box-shadow: none !important;
}

.btn-close:focus {
  box-shadow: none !important;}
  .btn-close{
    font-size: 13px;
  }
  .accordion-button:focus{
    box-shadow: none !important;
    outline: none !important;
  }
  .accordion-button:not(.collapsed){
    background: none !important;

  }
  .accordion{
    --bs-accordion-border-width : 0 !important
  }
  .accordion-button::after{
    --bs-accordion-btn-icon-width: 1rem !important;

  }`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;GAYG;;AAGH,EAAE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,gCAAgC;AAClC,kBAAkB;AAClB,UAAU;EACR,qBAAqB;EACrB,uBAAuB;AACzB;;AAEA;;EAEE,yBAAyB;EACzB,uBAAuB;EACvB,2BAA2B;EAC3B,2BAA2B;AAC7B;;AAEA;EACE,2BAA2B,CAAC;EAC5B;IACE,eAAe;EACjB;EACA;IACE,2BAA2B;IAC3B,wBAAwB;EAC1B;EACA;IACE,2BAA2B;;EAE7B;EACA;IACE;EACF;EACA;IACE,8CAA8C;;EAEhD","sourcesContent":["/* body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n} */\n@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');\n\n*{margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: \"Inter\", sans-serif;\ntext-align: center;\nz-index: 1;\n  /* padding: 0 90px; */\n  /* max-width: 1500px; */\n}\n\n.btn:hover,\n.btn:focus-visible {\n  color: inherit !important;\n  border: none !important;\n  background: none !important;\n  box-shadow: none !important;\n}\n\n.btn-close:focus {\n  box-shadow: none !important;}\n  .btn-close{\n    font-size: 13px;\n  }\n  .accordion-button:focus{\n    box-shadow: none !important;\n    outline: none !important;\n  }\n  .accordion-button:not(.collapsed){\n    background: none !important;\n\n  }\n  .accordion{\n    --bs-accordion-border-width : 0 !important\n  }\n  .accordion-button::after{\n    --bs-accordion-btn-icon-width: 1rem !important;\n\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
