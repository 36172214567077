// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Heading_head__DpRDn{
    margin: 100px 0 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.Heading_head__DpRDn h4{
    font-size: 35px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 10px;
    font-family: "Helvetica Neue Medium Extended";
    width: 65%;
}
.Heading_head__DpRDn p{
    margin: 0;
    font-size: 18px;
    color: #737373;
}

@media screen and (max-width : 620px) {
    .Heading_head__DpRDn{
        margin: 35px 0 20px  0;
    }
    .Heading_head__DpRDn h4{
        font-size: 25px;
        width: 100%;
    }
 }`, "",{"version":3,"sources":["webpack://./src/Components/Heading/Heading.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;AACf;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,SAAS;IACT,mBAAmB;IACnB,6CAA6C;IAC7C,UAAU;AACd;AACA;IACI,SAAS;IACT,eAAe;IACf,cAAc;AAClB;;AAEA;IACI;QACI,sBAAsB;IAC1B;IACA;QACI,eAAe;QACf,WAAW;IACf;CACH","sourcesContent":[".head{\n    margin: 100px 0 50px 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n}\n.head h4{\n    font-size: 35px;\n    font-weight: 500;\n    margin: 0;\n    margin-bottom: 10px;\n    font-family: \"Helvetica Neue Medium Extended\";\n    width: 65%;\n}\n.head p{\n    margin: 0;\n    font-size: 18px;\n    color: #737373;\n}\n\n@media screen and (max-width : 620px) {\n    .head{\n        margin: 35px 0 20px  0;\n    }\n    .head h4{\n        font-size: 25px;\n        width: 100%;\n    }\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"head": `Heading_head__DpRDn`
};
export default ___CSS_LOADER_EXPORT___;
