// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Faq_accord__csOjo{
    border: none !important;
    border-bottom: 1px solid #d9d9d9 !important;
    /* margin-bottom: 20px; */
}
.Faq_para__lyiDF{
    text-align: left !important;
    color: #5E6075;
    font-size: 16px;
    font-weight: 500;
    font-family: "Quicksand", sans-serif;
    padding-top: 0 !important;
}
.Faq_head__LP-Ge > button{
    font-size: 16px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
    /* padding-block: 0 !important; */
}
.Faq_foot__5y1TW{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0 100px 0;
}
.Faq_foot__5y1TW p{
    color: #5E6075;
    font-size: 16px;
}
.Faq_foot__5y1TW a{
    color: #105AB2;
    font-size: 16px;
}
@media screen and (max-width : 1400px) {
.Faq_accord_divv__bCLeM{
    padding-inline: 100px;
}
}
@media screen and (max-width : 620px) {
.Faq_foot__5y1TW{
    margin-bottom: 50px;
}
.Faq_accord_divv__bCLeM{
    padding-inline: 20px;
}
}
`, "",{"version":3,"sources":["webpack://./src/Components/FAQ/Faq.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,2CAA2C;IAC3C,yBAAyB;AAC7B;AACA;IACI,2BAA2B;IAC3B,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,oCAAoC;IACpC,yBAAyB;AAC7B;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,gCAAgC;IAChC,iCAAiC;AACrC;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;AAC1B;AACA;IACI,cAAc;IACd,eAAe;AACnB;AACA;IACI,cAAc;IACd,eAAe;AACnB;AACA;AACA;IACI,qBAAqB;AACzB;AACA;AACA;AACA;IACI,mBAAmB;AACvB;AACA;IACI,oBAAoB;AACxB;AACA","sourcesContent":[".accord{\n    border: none !important;\n    border-bottom: 1px solid #d9d9d9 !important;\n    /* margin-bottom: 20px; */\n}\n.para{\n    text-align: left !important;\n    color: #5E6075;\n    font-size: 16px;\n    font-weight: 500;\n    font-family: \"Quicksand\", sans-serif;\n    padding-top: 0 !important;\n}\n.head > button{\n    font-size: 16px;\n    font-weight: 600;\n    font-family: \"Inter\", sans-serif;\n    /* padding-block: 0 !important; */\n}\n.foot{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin: 30px 0 100px 0;\n}\n.foot p{\n    color: #5E6075;\n    font-size: 16px;\n}\n.foot a{\n    color: #105AB2;\n    font-size: 16px;\n}\n@media screen and (max-width : 1400px) {\n.accord_divv{\n    padding-inline: 100px;\n}\n}\n@media screen and (max-width : 620px) {\n.foot{\n    margin-bottom: 50px;\n}\n.accord_divv{\n    padding-inline: 20px;\n}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accord": `Faq_accord__csOjo`,
	"para": `Faq_para__lyiDF`,
	"head": `Faq_head__LP-Ge`,
	"foot": `Faq_foot__5y1TW`,
	"accord_divv": `Faq_accord_divv__bCLeM`
};
export default ___CSS_LOADER_EXPORT___;
