import React, { useState } from 'react'
import classes from "./Term.module.css"
import Footer from '../../Components/Footer/Footer';
import Navbar from '../../Components/Navbar/Navbar/Navbar';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';

const PrivacyPolicy = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  const navigate = useNavigate()
  const handleNavigate = () => {
    navigate('/')
  }
  return (
    <>
        <Navbar onSearchChange={handleSearchChange}/>
    <div className={classes.main_div}>
    <div className={classes.inn_div} onClick={handleNavigate}>
      <FaArrowLeft />
<span>Back</span>
      </div>
     <h4 className='text-start mb-4'>  1.	THE INTRODUCTION</h4> 

<p>CORELENS values the privacy of its customers. Security of your personal information, together with your person and possessions, is our first concern. The objective of this Privacy Policy is to outline the ways in which Corelens gathers, utilises, and discloses data collected from our home security systems, call centre, and online platforms (such as our website, mobile version, and chat function) that are under our control, such as www.corelens.in (collectively referred to as the "Site") or our mobile applications (the "App"). To learn about our services, please read this notice thoroughly. If you have any questions or concerns regarding our Privacy Policy, you may reach out to us using this website or the methods outlined in Section 10 of the document.

<p>Here are the different parts of our privacy policy:</p>

•	THE INTRODUCTION

<br/>• DETAILS ABOUT WHAT WE GATHER AND WHY

<br/>•	AREA OF ACCESS TO YOUR DATA AND OPTIONS

<br/>•	YOUR INFORMATION'S SECURITY

<br/>•	STATE PRIVACY RIGHTS IN DELHI AND OTHER CITIES

<br/>•	FINANCIAL INCENTIVE NOTIFICATION

<br/>•	RETENTION PERIOD

<br/>•	TRAVELLERS FROM OUTSIDE INDIA—TRANSFERS ACROSS BORDERS

<br/>•	REVISIONS TO OUR PRIVACY POLICY

<br/>•	INQUIRIES AND METHODS OF CONTACTING US
</p>

<h5>Authorised Third Party Sites</h5>

<p>Any information you provide via our website, mobile app, home security system, email, or phone calls will be subject to our Privacy Policy. Links on our site may take you to websites that are not associated with Corelens in any way. We want you to know that we have no control over the privacy policies of other websites. If you visit other websites that gather personally identifiable information, we recommend that you be wary and read their privacy policies before providing any personal information to them.
    </p>

<h5>Other Concepts</h5>

<p>If you want to know what our Terms of Sale are, read them before you buy anything from us. Additionally, the Website Terms of Service govern your use of the Site and the App. In addition, the Terms of Service apply to any features or services that are part of Corelens monitoring services or that you activate with a Corelens product, even if those features or services do not require a monitoring subscription.
    </p>


<h4 className='text-start mb-4 mt-5'>2. DETAILS ABOUT WHAT WE GATHER AND WHY</h4>

<p>When you register at our site, place an order for products or services through the site or our call centre, use our app, chat with a customer service representative, take part in a contest, survey, promotion, or activity, or communicate with us in any other way, Corelens collects certain information so you can make full use of our offerings. Visiting the Site is completely optional and does not require you to provide any personally identifiable information to us.

We are committed to protecting the privacy of our clients and will do our best to restrict the information we gather about them. We gather client information for a variety of reasons, including but not limited to: promoting our goods and services, informing consumers about new prospects, and running our business.
</p>

<p>Users of Our Site and Any Other Interactions With Us:</p>

<h5>•	We have the ability to gather the following types of personal data:</h5>

<p>1.	Identifying information (such as your name, address, email, phone number, device identifiers for tablets and smartphones, visitor identifiers, and IP address)
<br/>2.	Information pertaining to items or services you were interested in, as well as any other data collected from purchases or consumption
<br/>3.	Data associated with your use of the internet or other electronic networks, including but not limited to: the type of browser you are using, the operating system you are using, the number of visits to our site, the pages you viewed on our site, the time and date of your visit, the actions you took on our site (such as the pages you clicked on, the keys you pressed, and the name of your ISP), and more.
<br/>4.	Conclusions (such building a profile of your interests depending on your engagement with our website and our services)
<br/>5.	Information about your physical location (e.g., your IP address)</p>

<h5>•	The following sources were used to compile this data:</h5>

<p>1.	When you fill out our forms, take part in our surveys, contests, or other activities, or communicate with us in any way (e.g., over the phone, via email, text, or live chat), we collect the personal information you voluntarily give us.
<br/>2.	When you use our App or visit our Site, we automatically gather some of this information.
<br/>3.	Our advertising partners provide us with some of this data.
</p>
<h5>•	We are collecting this information for our business purposes.</h5>
<p>
1.	To keep you apprised of product developments and to propose services and goods that we believe might pique your interest
<br/>2.	For business, product, and service operations, site and app improvement, and quality assurance (QA) purposes related to specific interactions with you (e.g., Site chat and Customer Service calls),
<br/>3.	To fulfil your requests, get in touch with you when needed or asked, respond to your inquiries, comments, and offer customer support; to gather feedback from customers in order to enhance our customer service and their shopping experience; and to facilitate communication through technical service providers.
<br/>4.	For a select group of users, to record your session so we can analyse and fix any problems with the site's operation</p>

<h5>• This information is shared with third parties:</h5>
<p>
1.	Some outside parties who assist us with website hosting, website update emails, and user list data cleansing
<br/>2.	partners in analytics like Google, Amplitude, and Facebook
<br/>3.	Other parties who help us keep your Site and App experience safe and secure
<br/>4.	Third-party vendors that aid in our product and customer service development
<br/>5.	Any other party to whom we are required to disclose information by law or regulation, or to whom we believe it is usual, essential, or beneficial for the business reasons stated in this Privacy Policy
</p>

<h5>•	This information is shared with third parties as stated in the Indian Consumer Privacy Act:</h5>

<p>1.	We may share your information, including your browsing and shopping habits, with select third parties and strategic partners who make offers we believe might be beneficial to you.
<br/>2.	As members of an ad network, we may collect information about your online activities across participating websites in order to display ads for products and services when you visit those sites.
<br/>3.	We collaborate with ad networks like Google and Facebook to provide you with ads that are specific to what you're interested in.
<br/>4.	Just so there's no room for confusion, when we collect information from you using short code texting, we will not sell or distribute it for marketing reasons.</p>

<h4 className='text-start mb-4 mt-5'>Hey there, Corelens Customers!</h4>

<h5>•	The following types of personal information may have been gathered by Corelens if you have bought a product or service from Corelens:</h5>
<p>
1.	Identifiers (such as your name, email, phone number, address, details about your household, and what devices you use) We may also gather the contact details of users' friends and relatives for the purpose of sending them alerts or for our "Tell-A-Friend" programme.
<br />2.	Data pertaining to products and services (including details about your purchases and how you use them)
<br />3.	Payment Details (Corelens uses Razorpay to handle in-app purchases made with credit or debit cards; we will retain only the last four digits of your card number and its expiration date)
<br />4.	Whenever you use the internet or engage in other electronic network activities, we may collect certain information about you and your devices' interactions with our network, how you use our site and app, your visitor ID, how you use our products, the website you came from to reach our site, the time stamp information about your visit, the pages you view on our site (including pages visited), your mouse movements and keystrokes for site functionality evaluation, and the name of your internet service provider.
<br/>5.	Visual, auditory, and related data (in order to deliver the promised services, we capture audio and video from our security cameras and other camera-enabled equipment).
<br/>6.	Inferences (like a profile that reflects your interests and activities)
<br/>7.	Location Data (required for our goods and services to have Bluetooth and Wifi functions; to allow wifi capabilities, precise geolocation is only gathered once; other occurrences of geolocation gathering are imprecise)
<br/>8.	Privileged Identities (such as your race and ethnicity) and sensitive personal information (email address, password, or other credentials that grant access to your account; for example, safeword) that you knowingly and willingly divulge while responding to our surveys</p>

<h5>•	The following sources were used to compile this data:</h5>

<p>1.	Any and all interactions between us, whether spoken, written, electronic, or live chat, whether done in person, over the phone, or online. Just so you know, we record and save all conversations with our customer service department, including online chats.
<br/>2.	Information on your use of the Site and the App, including data gathered automatically and data that you voluntarily submit
<br/>3.	When you buy, set up, and use our services and goods, as well as contact with us, we get this information straight from you.
<br/>4.	When you use our websites or engage with our goods and services, we may combine information that you knowingly give us with data that we automatically gather.
<br/>5.	Analytics partners and advertising and marketing agencies provide us with this data.
</p>
<h5>•	We are collecting this information for our business purposes.</h5>

<p>1.	in order for us to provide you with our goods and services
<br/>2.	To notify you of critical service information and alarm status updates related to your alarm and our offerings
<br/>3.	To keep you informed on your orders and purchases
<br/>4.	To keep you apprised of product developments and to propose services and goods that we believe might pique your interest
<br/>5.	If you let it, to offer you monitoring services
<br/>6.	To identify and resolve security, fraud, or technical concerns
<br/>7.	To run and enhance our company, goods and services, website, and mobile app
<br/>8.	our goods and services to the public through advertising and marketing
<br/>9.	To find out what you think of our services and goods and how you feel about working with our firm.
<br/>10.	Refine the functionality of our website, services, and applications by analysing data collected through analytics.
<br/>11.	With your permission, to evaluate client interactions for quality assurance
<br/>12.	To give assistance to clients

</p>
<h5>•	This information is shared with third parties:</h5>

<p>1.	Our third-party service partners assist us with order processing, product fulfilment, and delivery. We may disclose your information to third parties if you authorise account connections or integrations with their products, such Corelens Amazon Alexa Skill, Apple Watch, or Google Assistant. You have complete control over these connections and integrations; we will never do this without your permission.
<br/>2.	Disclosure to third parties is necessary for the provision of our monitoring and emergency dispatch services, to which you may have subscribed.
<br/>3.	Third parties assist us with hosting the Site, doing marketing and advertising, removing repeated information from user lists, processing payments, and sending out site updates via email.
<br/>4.	Outside organisations that run contests, focus groups, and survey participants
<br/>5.	The companies who help us out, including the ones who host our website and the servers in the cloud
<br/>6.	We may share your information with third parties who can help you with system setup if you want them to do so.
<br/>7.	Just so there's no room for confusion, when we collect information from you using short code texting, we will not sell or distribute it for marketing reasons.
</p>

<h5>Data that is personally identifiable</h5>
<p>
Unless we are obligated by law to notify you or get your approval, we will not use your sensitive personal information for any additional purposes that are incompatible with the ones indicated above.</p>

<h5>Data Collected from the Security Camera and Other Camera-Enabled Devices</h5>

<p>Depending on your camera's configuration and settings, we will record, store, and process video and/or audio recordings from your device when you activate the recording capabilities. This includes security cameras and other camera-enabled products like Corelens' video doorbell. For example, we may analyse the data to detect motion or other occurrences, or we may capture and make available to you parts of the recorded data as a notice. In order to notify you of any events, we collect data from your camera-enabled device and keep it. For as long as your camera-enabled product's subscription period lasts, we will record, analyse, and preserve any audio and video footage you may have taken for up to 30 days from the initial recording (“Corelens’s date of first capture"). You will have 30 days from the date that Corelens initially captures such data during your membership period to retrieve those recordings. Within that 30-day period, you have the choice to remove or download these recordings from your account timeline. Downloading a recording will not affect how long Corelens keeps the footage on their servers.</p>

<h5>NOTE: It’s important to keep in mind that privacy laws in your state may have specific requirements for how you and the items you use with cameras, such as security cameras. It is entirely up to you, and not Corelens, to make sure that you follow all the rules when you use these camera-equipped goods. For instance, it's possible that you're obligated to post a notice (even within your own house) informing guests that you're employing a security camera or other device that can capture audio and video. Another thing to think about is that other people's privacy rights can be violated if you film and share videos involving them.


Data Files, Advertising Networks, Analytics Providers, and Pixels</h5>

<p>To learn more about our site's traffic and usage, we work with analytics partners. Cookies and pixels, also known as web beacons, allow us and our analytics and advertising partners to gather specific kinds of information whenever you use your computer or mobile device to access our Site.

When you access some websites, little text files called "cookies" are transferred to your device. These files typically include a string of characters. Thanks to the cookie, the website can identify your browser the next time you access it from that device. Unique identifiers, user preferences, and other types of information may be stored in cookies. Your browser has options to either notify you when a cookie is being sent or delete all cookies. On the other hand, cookies are necessary for the correct operation of various website features and services. By recording user preferences, tracking user trends, and offering relevant advertising to you, we utilise cookies to improve the quality of our service.

At the moment, we work with analytics partners including FullStory, Google Analytics, Facebook, Optimizely, and Amplitude. Visit the sites listed below for more information on how our analytics partners manage your personal information and how they interact with our Site. To better understand the behaviour of our site users and to gather data on website activity, Google Analytics makes use of pixels and cookies. Visit http://www.google.com/analytics/terms/us.html for additional information about Google Analytics. Go to https://tools.google.com/dlpage/gaoptout/ if you want to know how to disable Google Analytics. In order to enhance our Site, we utilise Optimizely, a third-party website optimisation tool, to examine your behaviour on our Site and our connection with you. You may find more details about Optimizely's privacy practices at https://www.optimizely.com/privacy. Follow the steps at http://www.optimizely.com/opt_out to unsubscribe from Optimizely at any moment.

In order to identify unusual site behaviour and resolve functionality issues, we employ FullStory, a session recording tool, on a tiny number of user sessions on our Site. Please see https://www.fullstory.com/legal/privacy-policy for additional details regarding FullStory's privacy policies. Our membership in an ad network gives them the ability to track your online activities across partner websites in order to provide you with more relevant ads. For the time being, we are unable to alter our Site's behaviour in response to "do not track" signals or other browser mechanisms that express a desire to avoid tracking or interest-based advertising. The websites of the Digital Advertising Alliance and the Network Advertising Initiative (www.aboutads.info and www.networkadvertising.org, respectively) provide further information about interest-based adverts as well as your rights and alternatives to opt-out of them.
</p>

<h5>Third-Party Interactions with Corelens Products or Accounts</h5>

<p>Corelens may share some information about you with third parties for account authentication and management, as well as to activate, connect, and integrate with third party products and services on their platforms. This is necessary if Corelens offers any product or account connections or integrations with third party products, applications, functionality, or services (collectively, "Third-Party Products and Services"). If you decide to enable or connect to these Third-Party Products and Services through the authorised and directed means, you are acknowledging and agreeing that Corelens and/or these third parties may share some information about you. For instance, if you decide to use Corelens Amazon Alexa Skill, Apple Watch, or Google Assistant (whenever they come out), you're essentially giving us permission to share some personal information with those third parties so they can activate, connect, and integrate with the Third Party Products and Services you're interested in. As discussed below, Corelens or the third-party partner may request authorization to manage the Corelens and/or Third-Party goods and services that you have enabled and linked. Allowing any of these exchanges or requests for control requires your approval, which you may cancel or deactivate at any moment.

Also, with your consent, Corelens may share data with these outside parties. When you sign up for or use Third-Party Products and Services, Corelens and our partner may ask for your consent to share some information. In some cases, Corelens needs your permission to share your information. Your Corelens account information may be supplemented with data we obtain from third parties in order to assess programme effectiveness and eligibility.

Your Corelens account information may be shared with other parties in certain restricted circumstances, as explained in the section labelled "Information We Collect and Why We Collect It."

The third party's privacy policy will govern any Corelens data that you choose to share with them, whether that's directly or through the authorizations, integrations, and connections allowed by this Privacy Policy.

In compliance with this Privacy Policy, Corelens will handle and keep any data received from third parties. Your Corelens account data, including this information, may be handled in the same manner as other data.
</p>

<h5>No Data Collected From Minors Under the Age of 18</h5>

<p>We do not intentionally solicit data from children or teens under the age of 18 and this Site is not intended for their use. No one under the age of 18 should ever use this Site or provide us any personally identifiable information. Under no circumstances may anybody under the legal age of 18 access or use this Site without the involvement of an adult.

Under no circumstances does the Site intentionally collect or solicit personal information from children under the age of thirteen without the explicit and verified agreement of their parents or legal guardians. Corelens will take all reasonable steps to delete any personally identifiable information submitted by a child under the age of 13 from its databases and will not use this information for any purpose (except to the extent that it is required or permitted by law to protect the safety of the child or others) if we learn of this violation. Please use the website's contact form to notify us if you learn that we have acquired personally identifiable information from minors under the age of 13.

We are unaware that we have sold or shared the personal information of anybody under the age of 16.</p>

<h5>Social Media and Other Third-Party Websites</h5>

<p>In addition to social networking tools like Facebook, Twitter, and Pinterest buttons, our site may include links to other websites that are run by third parties. When you visit a site that contains a social networking button, the social media site may automatically record information about your surfing behaviour. Additionally, third-party sites may collect information about you if you click on a link. The terms of service and privacy policies of the partnering business, and not Corelens's, will govern your interactions with these services. Therefore, before providing any personal information on any external site, we advise you to read their privacy policy. Should you possess any inquiries concerning the privacy policies of those websites, kindly go out to them individually.</p>

<h5>Refer a Friend</h5>
<p>
The "Refer A Friend" form requires you to provide the name and email address of the person you are recommending. To fulfil your request for communication and to track how well our "Refer A Friend" service is working, we will only utilise the information you provide.</p>

<h5>
Proceedings in Court</h5>

<p>With a good-faith conviction that access, use, preservation, or disclosure of the information is reasonably necessary to:

<br/>•	Comply with any relevant statutes, rules, subpoenas, court orders, or official requests.

<br/>•	Investigate any possible infractions of the Terms of Service, Terms of Use, and Terms of Sale that may be applicable.

<br/>•	Find, stop, or handle any problems related to fraud, security, or technology.

<br/>•	As mandated or authorised by law, safeguard Corelens's rights, property, or safety as well as those of our users, customers, or the general public.

<br/>•	In order to comply with legitimate demands made by public authorities, such as those pertaining to national security or law enforcement, we may be compelled to reveal your personal information in some situations.
</p>

<h5>Change of Control or Sale-Related Transfer</h5>

<p>Your personal information may be transferred or sold to a third party if our business is acquired or if we sell or otherwise transfer assets related to our business or the Site to a third party, whether via a merger, acquisition, bankruptcy, or any other legal process. Your information would still be bound by the provisions of the privacy policy that was in place when the transfer occurred, unless there was a legal requirement to do differently.</p>

<h4 className='text-start mb-4 mt-5'>3. AREA OF ACCESS TO YOUR DATA AND OPTIONS</h4>
<p>
By accessing your account using our web application at www.corelens.in, you are able to view and modify some of the information that we hold on your account. Head over to the "Monitoring" area to revise your details for the alarm's deployment and monitoring. Go to the "Manage Account" part of the site to change your login details (password, email).

Navigate to the "Location Profile" section of our mobile app's menu after logging in to change your contact details for alarm monitoring and dispatch. Choose "Manage Account" from the menu to change your login details (email, password) on our mobile app. Contact Us with this form if you have any inquiries regarding the personal data that we hold on file for you. By going into your account and modifying your profile, you have the option to not receive any more messages from us (apart from service information and alarm notifications). Corelens marketing emails include an opt-out or unsubscribe link in the bottom, so you can choose not to receive these emails in the future.

Call us at 1800-313-4204 to cancel your online account. We promise not to utilise or disclose any information pertaining to your closed account to any third parties. To prevent fraud or identity theft, we may, nevertheless, keep some of your personal data for as long as is required by law, for legitimate business reasons, or for archive purposes.</p>

<h4 className='text-start mb-4 mt-5'>4. YOUR INFORMATION'S SECURITY
</h4>

<p>To maintain the privacy and integrity of your personal information, we employ appropriate administrative, technological, and physical security measures. Unfortunately, we cannot promise, assure, or represent that any information you communicate to us will be completely safe over the Internet. No amount of precaution, whether physical, technological, or administrative, can ensure that data will remain secure against unauthorised access, disclosure, alteration, or destruction. It is important to remember that, except for some channels like secure chat, none of the communications you submit to us through our Site are encrypted. So, please do not disclose any sensitive information using these methods.</p>

<h4 className='text-start mb-4 mt-5'>5. STATE PRIVACY RIGHTS IN DELHI AND OTHER CITIES
    </h4>
    <p>

Residents of Delhi and other states will have some rights with regard to their personal information under their privacy laws (beginning on December 31, 2023). For further information, please refer to the parts below that are relevant to each state.

Various forms of personally identifiable information (such as your name, alias, postal address, unique identifier(s)), online identifier(s), email address, account name, social security number, driver's licence number, passport number, or other comparable numbers) are considered "personal information" in the context of this privacy notice. Data that is not personally identifiable does not include data that is already in the public domain, data that is subject to other regulations or de-identified data that cannot be associated with a particular person.

<p className='mt-4' style={{textDecoration:"underline"}}>Rights Regarding Your Personal Information </p>

•	We are obligated to comply with your request for any of the following information under the Right to Know:

<br/>1.	detail the types of personally identifiable information we have gathered, used, released, and "sold" from you
<br/>2.	the kind of entities that provide the personal data collectors with their requests
<br/>3.	the reason(s) for gathering, selling, or disclosing personal data as it relates to a business or commercial endeavour
<br/>4.	to what kind of other parties does Corelens share customer data with?
<br/>5.	the particular bits of personally identifiable information that Corelens has gathered from you.

•	By considering the kind of personal information and its intended use, you have the right to rectify any errors in your personal data. See this part of our Privacy Policy for further details on how to use this right.

•	You have the option to ask us to remove any personal data we have stored about you. Corelens may store personal information in some situations to comply with legal requirements or to enable necessary functionality, such keeping your membership active. Please be aware that this right is not absolute.

•	In order to prevent your personal information from being sold or shared, you have the option to use this form to do so. The indication of your chosen privacy settings can be removed at any moment by erasing your cookies.

•	By submitting this form, you are able to exercise your right to restrict the use of your sensitive personal information.

This form allows you to do things like exercise your right to know, request deletion of your information, or have it corrected. You may also use it to say "Do not sell my personal information" if you don't want your data sold or shared. If you like, you can also use this form to authorise another person to submit a request on your behalf when using our website.

In order to ensure that you are who you say you are when you make a request or use your rights, Corelens will ask for information such as your email address, the number of an order for our products or services in the past, the serial number of your device, or the last four digits of a credit or debit card. Unless there are valid exceptions provided by the Indian Consumer Privacy Act, we shall try our best to comply with such requests. It is important to mention that our obligation to comply with Right to Know requests is limited to two times per calendar year.

If a person of Delhi wants to use any of the rights outlined in our Privacy Policy, we won't treat them any differently. The quality and cost of our products and services will be unaffected by your use of these rights.

Furthermore, as explained further on, we are obligated by Indian law to disclose any information that we may have "sold" or "shared" with third parties regarding you within the twelve months preceding the date of this Privacy Policy. Only in accordance with the practices detailed above has Corelens shared or sold personally identifiable information about its customers during the twelve months before the date of this Privacy Policy. Except as required or authorised by law, Corelens will not use or disclose any information that is considered sensitive personal information.

To further provide you with options to request that we not share or sell your personal information, we are working on adding support for Global Privacy Control (GPC) signals in browsers and extensions that allow you to do so. Up until then, you may prevent third parties from using your browser's cookie settings or visit our opt-out of sale preference centre to prevent personalised advertising.

Earlier, we discussed how we may disclose your information for marketing purposes. This Privacy Policy explains how we do that. You have the right as an Indian resident to request and receive from us, once a year, details regarding the types of personal information we have shared with third parties for their direct marketing purposes, as well as the names and addresses of those businesses with whom we have shared this information. To get this information or if you have any other inquiries regarding our privacy policies or our adherence to Indian legislation, kindly reach out to us via our website.

<p className='mt-4' style={{textDecoration:"underline"}}>Rights to Privacy in India</p>

•	You have the right to inspect and verify that we are processing your personal data.

<br/>•	With consideration for the kind of personal data and the reasons for processing, you have the right to rectify errors in your personal data. This part of our Privacy Policy explains how to do this action in more detail.

<br/>•	The option to have any personally identifiable information we may have on file erased. Corelens may store personal information in some situations to comply with legal requirements or to enable necessary functionality, such keeping your membership active. Please be aware that this right is not absolute.

<br/>•	If you do not want your personal information used for targeted advertising, sold, or profiled to support choices that have legal or similarly substantial consequences, you have the right to opt out.

<br/>•	You have the right to request a copy of the personal data we have collected from you in a format that is easily readable and, if possible, portable.

<br/>•	Kindly be informed that Corelens will not use machine learning or profiling techniques to handle your personal data in a way that might legally or significantly affect you.

<br/>•	You have the right to challenge our judgement if we reject your request.


To exercise your right to access, erasure, or correction of your personal information, or to opt out of its sale or sharing, you can use this form. If you like, you can also use this form to authorise another person to submit a request on your behalf when using our website.

You will be asked to log in to your account (if you have one with us) in order to authenticate your identity when you exercise these rights and make a request to us. Or, if you're not sure, we could ask you to verify some personal information that Corelens has on file for you, including your email address, order numbers from past purchases, equipment serial numbers, or the last four digits of a credit or debit card number. Unless there are valid exceptions under the consumer privacy legislation in your state, we will do our best to comply with such requests.

Our products and services will remain unchanged in both price and quality regardless of your decision to use these rights.

You can utilise the contact form on our website to appeal a decision we have made about your request. Within 60 days, we will get back to people living in India. All appeals from non-resident aliens will get a response from us within 45 days.

<p className='mt-4' style={{textDecoration:"underline"}}>Beginning December 31, 2023, Your Rights Regarding Privacy</p>

•	You have the right to inspect and verify that we are processing your personal data.

<br/>•	The option to remove any personally identifiable information we may have collected from you. Corelens may preserve personal information in some situations to comply with legal requirements and to facilitate necessary operations, such subscription management, so please be aware that this right is not absolute.

<br/>•	You have the option to avoid having your personal data used for targeted advertising or sold.

<br/>•	You are entitled to receive a copy of your personally identifiable information that you have already supplied to us in a format that is both portable and, to the degree that it is technically possible, easily useable.

To exercise your right to know and/or right to deletion, or to opt out of the sale and sharing of personal information, you may use this form. You will be asked to log in to your account (if you have one with us) in order to authenticate your identity when you exercise these rights and make a request to us. If you do not, Corelens may request that you verify details such as your email address, order numbers from past purchases, equipment serial numbers, or the last four digits of a credit or debit card that was used for a purchase. We shall do our best to comply with such requests unless they are in violation with the Privacy Rights Act's legitimate exclusions.

Our products and services will remain unchanged in both price and quality regardless of your decision to use these rights.
</p>
<h4 className='text-start mb-4 mt-5'>6. FINANCIAL INCENTIVE NOTIFICATION</h4>

<p>"Promotional Campaigns" refers to the programmes that we may provide, which may include contests, surveys, and similar initiatives. Financial incentives and/or pricing or service differences, like a voucher or discount, may be offered to you in return for your participation in our Promotional Campaigns and the provision of personal information, such your name and contact details. As outlined in the relevant Promotional Campaign rules, you are free to withdraw from the programme at any moment. Our best guess is that the advantage we provide to you in conjunction with the promotion, which is reasonably tied to the expenses of giving such benefit, is the worth of the personal information we gather for Promotional Campaigns.</p>


<h4 className='text-start mb-4 mt-5'>7. RETENTION PERIOD</h4>

<p>We promise to keep your data for as little time as is absolutely required to fulfil our service to you, meet our legal requirements, settle any disputes that may arise, or enforce our agreements. We consider the following factors when deciding how long to keep customer data: (a) the duration of our relationship with the customer and the services they receive from us, (b) any applicable legal requirements, and (c) the necessity of the data to support our legal position, such as in cases of litigation, regulatory investigations, or applicable statutes of limitations.

For instance, unless you tell us differently, we will delete all videos after 30 days. We keep call recordings for no more than six months, and call transcripts for no more than eighteen months.</p>

<h4 className='text-start mb-4 mt-5'>8. TRAVELLERS FROM OUTSIDE INDIA—TRANSFERS ACROSS BORDERS</h4>
<p>
India is the location of the site's hosting. Your information may be transmitted to, stored in, and processed in the United States or other countries in compliance with this Privacy Policy if you are accessing the Site from outside of India. There is a possibility that the data privacy and consumer protection rules in your nation are more thorough and comprehensive than the laws and regulations in India or these other nations. Government officials in such jurisdictions may have access to your information if they are legally required to do so. You are giving your agreement to transmit your information to our facilities as indicated in this Privacy Policy when you use the Site and/or provide us with personal information.</p>

<h4 className='text-start mb-4 mt-5'>9. REVISIONS TO OUR PRIVACY POLICY</h4>
<p>
While we reserve the right to make changes to this policy at any moment, we will examine it annually at the very least. In compliance with relevant laws, we will not limit your rights as outlined in this Privacy Policy without your explicit approval. If we make any changes to our privacy policies, we will put them here and, if they are substantial, we will also notify you by email and, if necessary, in a more prominent location.</p>

<h4 className='text-start mb-4 mt-5'>10. INQUIRIES AND METHODS OF CONTACTING US</h4>
<p>
Use this contact form or send us a letter at connect@corelens.in if you need to get in touch with us for any reason, including but not limited to questions, complaints, recommendations, or issues about our Privacy Policy.</p>

    </div>
     <Footer/>
     </>
    
  )
}

export default PrivacyPolicy