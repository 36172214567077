import React, { useState } from 'react'
import classes from "./Term.module.css"
import Navbar from '../../Components/Navbar/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import { FaArrowLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

const TermUse = () => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (value) => {
      setSearchTerm(value);
    };

    const navigate = useNavigate()
    const handleNavigate = () => {
      navigate('/')
    }
    return (
        <>
        <Navbar onSearchChange={handleSearchChange}/>
                <div className={classes.main_div}>
                <div className={classes.inn_div} onClick={handleNavigate}>
      <FaArrowLeft />
<span>Back</span>
      </div>
            <h3 className='text-start mb-5'>Terms of Use</h3>

            <h6 className='text-start my-3'>LAST REVISED: FEBRUARY 2020</h6>

            <p>The conditions of use are agreed upon by You and Corelens, Inc. ("Corelens", "Company", "we" or "us"). The <strong>"Terms of Use"</strong> outlined in this document, along with any additional documents, agreements, or policies referenced within, govern your access to and utilisation of www.corelens.in or any other mobile or web interface currently in existence or that may be developed in the future (such as mobile sites or applications, collectively referred to as "Online Applications"). This includes all content and features provided on or through www.corelens.in or any Online Applications, collectively referred to as the "Website". The word "you," as used in these Terms of Use, refers to any individual or organisation that visits or utilises the Website.

                Prior to commencing usage of the Website, it is essential that you thoroughly review and understand these Terms of usage. By using the Website, you acknowledge and consent to be legally obligated to comply with these Terms of Use and our Privacy Policy, which can be accessed at www.Corelens.in/privacy-policy and are included in this agreement by reference. If you are unwilling to consent to these Terms of Use or the Privacy Policy, you are prohibited from accessing or using the Website.</p>

            <p>The Website is accessible to those who are 18 years of age or above. By using the Website, you affirm and guarantee that you satisfy the aforementioned eligibility criteria. If you choose not to, you are prohibited from accessing or using the Website. Furthermore, the items and services shown on the Website are only accessible inside the borders of India. You are prohibited from using or attempting to use any Corelens product or service outside of India or for any illegal or criminal purpose.</p>

            <p>The Terms of Service regulate your utilisation of any products or services you have chosen to obtain from Corelens, encompassing account information, account management, and all purchases made through our Website or other transactions for the sale of products or services formed through the Website are subject to our Terms of Sale. These Terms of Use are supplementary to any other applicable terms that may pertain to you. In the event of any discrepancies between these Terms of Use and the Terms of Service or Terms of Sale, the Terms of Service or Terms of Sale will take precedence and be in control, accordingly (and only as appropriate). <strong>Claims and disputes that arise from the Terms of Service or Terms of Sale will only be governed by those terms, not by these Terms of Use.</strong></p>

            <h5>1. Modifications to the Terms of Use and Website</h5>
            <p>In our exclusive judgement, we reserve the right to modify and enhance these Terms of Use periodically. The modifications take effect immediately upon posting and apply to any subsequent access and use of the Website. If you continue to use the Website after amended Terms of Use have been posted, it indicates that you accept and consent to the changes. Nevertheless, any modifications to the dispute resolution procedures stated in the Governing Law and Jurisdiction will not be applicable to any issues that the parties were already aware of before the date the change is published on the Website. Furthermore, we reserve the right to periodically modify the material on the Website; nevertheless, it should be noted that the content may not be comprehensive or current. The content on the Website may become outdated at any moment, and we have no responsibility to refresh or revise such content.</p>

            <h5>2. Gaining access to the website</h5>
            <p>In our sole discretion, we have the right to remove or modify the Website, as well as any services or materials provided on the Website, without prior notice. We will not be held responsible in the event that the Website, or any portion thereof, becomes inaccessible at any given moment or for any duration.</p>

            <h5>3. Intellectual Property Rights</h5>
            <p>The Website and all of its contents, features, and functionality (including, but not limited to, all information, software, text, displays, images, video, and audio, as well as the design, selection, and arrangement of these elements) are the property of Corelens, its licensors, or other providers of such material. They are protected by copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws in India and internationally.

                These Terms of Use allow you to using the Website only for your personal, non-commercial purposes. It is prohibited to reproduce, distribute, edit, make derivative works of, publicly display, publicly perform, republish, download, store, or transmit any content on our Website, except for personal use. If we include social networking functionalities, such as Facebook, Twitter, Pinterest, Google+, or other future platforms, and offer them with specific material, you are authorised to use the available actions provided by these features.</p>

            <h5>Prohibited actions:</h5>

            <p>
                <ul>
                    <li className='text-start'>Alter duplicates of any items obtained from the Website.
                    </li>
                    <li className='text-start'>Utilise images, pictures, video or audio sequences, or other visuals independently from the underlying text.</li>
                    <li className='text-start'>Remove or modify any copyright, trademark, or other proprietary rights notices from copies of content obtained from this site.</li>
                </ul>

                You do not acquire any ownership, legal claim, or interest in the Website or its content under any circumstances. The Company retains all rights that are not explicitly given to you. Engaging in any activity on the Website that is not explicitly authorised by these Terms of Use constitutes a violation of these Terms of Use and may infringe against copyright, trademark, and other legal regulations.</p>
            <p>
                The software used to support, power, or run the Website may include open source software. Every each piece of open source software is bound by its own specific licence conditions. The copyright holders specified in the open source software retain the copyrights.</p>

            <h5>4.Trademarks</h5>
            <p>The trademarks of Corelens, including their respective logo, names, logos, product and service names, designs, and slogans, are owned by Corelens or its affiliates or licensees. It is imperative that you refrain from use these marks without obtaining the explicit written consent of Corelens. The trademarks of their respective owners include all other names, logos, product and service names, designs, and slogans on the Website.</p>

            <h5>5. Restricted Activities</h5>
            <p>The Website may only be used for legal purposes and must be used in compliance with these Terms of Use. You are obligated to refrain from using the Website:

                <ul>
                    <li className='text-start'>Any action that contravenes any relevant centre, state, municipal, or international legislation or regulation (including, but not limited to, regulations pertaining to the transfer of data or software to and from India or other countries).</li>
                    <li className='text-start'>This text pertains to the act of exploiting or causing damage to kids by exposing them to unsuitable material, soliciting their personal information, or engaging in any other harmful activities.</li>
                    <li className='text-start'>It is prohibited to engage in the actions of sending, intentionally receiving, uploading, downloading, using, or re-using any content that does not adhere to the terms stated in these Terms of Use.</li>
                    <li className='text-start'>To transmit or facilitate the transmission of any commercial or promotional material, including unsolicited bulk emails, chain letters, spam, or any other such solicitation.</li>
                    <li className='text-start'>Engaging in the act of impersonating or making an effort to impersonate Corelens, a Corelens staff member, another user, or any other individual or organisation, which includes utilising email addresses or screen names connected with any of the aforementioned parties.</li>
                    <li className='text-start'>To participate in any behaviour that limits or hinders someone's ability to use or enjoy the Website, or that, as judged by us, may cause damage to Corelens or users of the Website or subject them to legal responsibility.</li>
                </ul>
	</p>

            <p>In addition, you promise to refrain from:</p>
            <p>
                <ul>
                    <li className='text-start'>Do not use the Website or any tools that might potentially disable, overload, harm, or hinder the normal functioning of the Website or disrupt other users' ability to participate in real-time activities on the Website.</li>
                    <li className='text-start'>Do not use any robot, spider, or other automated device, process, or method to gain access to the Website for any reason, including the monitoring or duplication of any content found on the Website.</li>
                    <li className='text-start'>Do not introduce any viruses, trojan horses, worms, logic bombs, or any other malicious or technologically damaging material.</li>
                    <li className='text-start'>Do not try to gain unauthorised access, interfere with, damage, or disrupt any elements of the Website, the server where the Website is hosted, or any server, computer, or database connected to the Website.</li>
                    <li className='text-start'>Launch a cyber assault on the website by using a denial-of-service attack or a distributed denial-of-service attack.</li>
                    <li className='text-start'>Otherwise, refrain from attempting to disrupt the normal functioning of the Website.</li>
                </ul>
               	</p>

            <h5>6. Contributions made by the user</h5>
            <p>The Website may include various Interactive Services such as message boards, chat rooms, surveys, personal web pages or profiles, forums, bulletin boards, and other features. These services allow users to post, submit, publish, display, or transmit content or materials, referred to as User Contributions, to other users or persons through the Website.</p>

            <p>All contributions made by users must adhere to the Content Standards outlined in these Terms of Use.</p>

            <p>Any User Contribution you submit to the site will be treated as public and not owned by you. By submitting any User Contribution on the Website, you give us, our affiliates, and our service providers, as well as their and our respective licensees, successors, and assigns, the permission to utilise, alter, present, distribute, and otherwise reveal such material to third parties. You bear full responsibility for any User Contributions that you submit or contribute.</p>

            <p>You affirm and guarantee that:
                <ul>
                    <li className='text-start'>You possess or have authority over all the rights pertaining to the User Contributions and possess the authority to bestow the licence mentioned above.</li>
                    <li className='text-start'>Your User Contributions must adhere to these Terms of Use, both now and in the future.</li>
                </ul>
                	</p>

            <h5>7. Monitoring and Enforcement; Termination</h5>
            We are entitled to:
            <p>
                <ul>
                <li className='text-start'>We have the right to delete or reject any User Contributions at our discretion, without providing a reason.</li>
                <li className='text-start'>We reserve the right to take any action regarding any User Contribution that we consider necessary or appropriate, at our sole discretion. This includes taking action if we believe that the User Contribution violates the Terms of Use, including the Content Standards, infringes upon any intellectual property or other rights of any individual or entity, poses a threat to the personal safety of Website users or the general public, or could potentially create liability for the Company.</li>
                <li className='text-start'>Initiate necessary legal proceedings, such as reporting to law enforcement, in response to any unlawful or unauthorised use of the Website.</li>
                </ul>
               	</p>

            <p>We do not commit to reviewing every content prior to its publication on the Website and cannot guarantee the immediate removal of offensive material after it has been submitted. Therefore, we do not accept responsibility for any actions or lack of actions related to the transmission, communication, or material supplied by users or third parties. We bear no liability or duty to any individual for the execution or failure to execute the actions outlined in this section.</p>

            <h5>8. Content Standards</h5>
            <p>These content guidelines are applicable to all User Contributions and the use of Interactive Services. User Contributions must fully adhere to all relevant federal, state, municipal, and international laws and regulations. User Contributions must not include any limitations, among other things.</p>

            <p>
                <ul>
                <li className='text-start'>Do not include any content that is defamatory, obscene, indecent, abusive, insulting, harassing, violent, hateful, inflammatory, or otherwise objectionable.</li>
                <li className='text-start'>Engage in the promotion of sexually explicit or pornographic content, violence, or any kind of discrimination rooted in race, sex, religion, nationality, handicap, sexual orientation, or age.</li>
                <li className='text-start'>Violate or encroach upon any patent, trademark, trade secret, copyright, or other intellectual property or rights of any other individual.</li>
                <li className='text-start'>Engage in actions that infringe upon the legal rights, such as the rights of publicity and privacy, of others or include any content that might result in civil or criminal responsibility under relevant laws or regulations, or that may contradict our Terms of Use and our Privacy Policy.</li>
                <li className='text-start'>Possess the tendency to mislead or trick any individual.</li>
                <li className='text-start'>Encouraging or endorsing any criminal conduct, including advocating, promoting, or aiding any unlawful act.</li>
                <li className='text-start'>Provoke irritation, discomfort, or unnecessary distress, or have the potential to disturb, humiliate, alarm, or irritate any individual.</li>
                <li className='text-start'>Engaging in impersonation of any individual or deliberately misrepresenting your identity or association with any person or organisation is prohibited.</li>
                <li className='text-start'>Include commercial endeavours or transactions, such as competitions, giveaways, and other marketing campaigns, exchange of goods or services, or promotional activities.</li>
                <li className='text-start'>Convey the perception that they originate from or are supported by us or any other individual or organisation, if this is not true.</li>
                </ul>
               
            </p>
            <h5>9. Reports of copyright infringement</h5>
            <p>
                If you have reason to suspect that any User Contributions infringe upon your copyright, please email us a notification of the copyright infringement. The Company's policy is to cancel the user accounts of those who repeatedly infringe against copyright or intellectual property rights. To report any instances of infringement, you may submit a notice to our Designated Agent using the contact information provided below:</p>

            <p>
                <ul>
                <li className='text-start'>The designated agent to receive notification of claimed infringement is PANKAJ GARG.</li>
                <li className='text-start'>The complete address of the designated agent to whom notifications should be sent is 3/2287/39 K PUL, Delhi 110006.</li>
                <li className='text-start'>The telephone number of the designated agent is +91 9968425548.</li>
                <li className='text-start'>The designated agent's email address is connect@corelens.com.</li>
                </ul>
            </p>

            <p>Refer to 17 U.S.C. §512(c)(3) to find the specific criteria that must be met for a notice to be considered valid. Kindly be aware that the information included in your notification, including any personal data, may be shared with the individual who has allegedly provided the infringing content. By sending us this notification, you are giving your consent to share this information with the alleged infringer.</p>

            <p>It is important to be aware that if you provide false information in your notification about material or activity being infringing, you will be responsible for any damages, expenses, and legal fees incurred by us or the alleged infringer due to our reliance on that false information to remove or disable access to the claimed infringing material or activity.</p>

            <p>We have the option to inform our users about a claim of copyright infringement by a general notice on the Website, sending an email to the user's email address on our data, or by sending a written communication via first-class mail to the user's address on our records.</p>

            <h5>10. No Reliance on Information Posted</h5>
            <p>The information provided on or via the Website is only intended for general informational purposes. This material is not guaranteed to be accurate, comprehensive, or helpful. Any trust or dependence you have on such material is entirely your responsibility and may include potential dangers. We absolve ourselves of all legal obligation or liability for any trust or dependence placed on these materials by you or any other visitor to the Website, or by anyone who becomes aware of its contents.</p>

            <p>The Website may include content sourced from other parties, such as items contributed by other users, bloggers, and third-party licensors, syndicators, aggregators, and/or reporting services. The thoughts and assertions stated in these materials, including articles and replies to inquiries, are the exclusive responsibility of the person or organisation giving them, and do not represent the views of the Company. The views expressed in these materials may not always align with the viewpoint of the Company. We disclaim all responsibility or liability to you or any other party for the content or accuracy of any materials supplied by third parties.</p>

            <h5>11. User and Website Visit Information</h5>
            <p>Kindly see Corelens's Privacy Policy, available at www.Corelens.com/privacy-policy, for crucial details on the gathering, use, and dissemination of your personal data. By using the Website, you provide your permission to any acts carried out by us in relation to your information, in accordance with the Privacy Policy.</p>

            <h5>12. Linking to the Website and Social Media Features
            </h5>
            <p>You are allowed to include a link to our homepage, as long as you do so in a manner that is fair and lawful, and does not harm our reputation or exploit it. However, you are not permitted to create a link that implies any kind of affiliation, approval, or endorsement from us without our explicit written permission.</p>
            <p>
                The Website may provide certain social networking functionalities that allow you to:</p>

            <p>
                <ul>
                <li className='text-start'>Establish a connection between certain material on the Website and either your own website or specific third-party websites.</li>
                <li className='text-start'>Transmit electronic messages or other forms of communication containing specific material, or hyperlinks to specific content, on the Website.</li>
                <li className='text-start'>Enable the display of restricted sections of material from the Website on your own or certain third-party websites.</li>

                </ul>
              	</p>

            <p>You are only allowed to use these features in the exact way we give them, specifically in relation to the material they are shown alongside. Additionally, you must follow any extra terms and conditions we provide for these services. With respect to what has been previously said, you are prohibited from:</p>

            <p>
                <ul>
                <li className='text-start'>Create a hyperlink from a website that you do not control.</li>
                <li className='text-start'>Prohibit the display of the Website or any of its parts on any other site, such as by framing, deep linking, or in-line linking.</li>
                <li className='text-start'>Provide a hyperlink to any section of the website that is not the main page.</li>
                <li className='text-start'>Otherwise, refrain from taking any action regarding the content on the Website that contradicts any other condition of these Terms of Use.</li>

                </ul>
                </p>
            <p>
                You promise to collaborate with us to promptly terminate any unauthorised framing or linking. We retain the right to revoke connecting authorization without prior notification.</p>

            <h5>13. Links from the Website</h5>
            <p>The inclusion of hyperlinks to other websites and resources on the Website is only for the purpose of convenience. This includes hyperlinks included in adverts, such as banner advertisements and sponsored links. We do not have authority over the contents of such sites or resources and assume no liability for them or for any potential harm or loss that may result from your use of them. By choosing to visit any of the other websites connected to our website, you assume full responsibility and are bound by the terms and conditions of those websites.</p>

            <h5>14. Limitations based on geographical location</h5>
            <p>The website's proprietor is located in India. The Website is only intended for those residing within the geographical boundaries of India. The Website and its content are not guaranteed to be accessible or suitable for use outside of India. Access to the Website may be prohibited for individuals or in certain jurisdictions due to legal restrictions. If you visit the Website from a location outside of India, you are doing so voluntarily and are accountable for adhering to the laws of that particular region.</p>

            <h5>15. Disclaimer of Warranties</h5>
            <p>YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. WE DISCLAIM ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER USERS. PRODUCT REVIEWS AND COMMENTS ARE STRICTLY THE OPINION OF THE USER POSTING SUCH REVIEWS OR COMMENTS, AND THE COMPANY DOES NOT ENDORSE OR APPROVE ANY SUCH REVIEWS OR COMMENTS. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. WE ARE NOT RESPONSIBLE FOR TYPOGRAPHICAL ERRORS OR OMISSIONS RELATING TO PRICING, TEXT, OR PHOTOGRAPHY. WHILE WE ATTEMPT TO MAKE YOUR ACCESS AND USE OF THE WEBSITE SAFE, WE CANNOT AND DO NOT REPRESENT OR WARRANT THAT OUR WEBSITE OR ITS SERVER(S) ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, THEREFORE, YOU SHOULD USE INDUSTRY-RECOGNIZED SOFTWARE TO DETECT AND DISINFECT VIRUSES FROM ANY DOWNLOAD.</p>

            <p>THIS PROVISION DOES NOT IMPACT ANY WARRANTIES THAT ARE NOT ABLE TO BE EXCLUDED OR LIMITED BY APPLICABLE LAW.</p>

            <h5>16.Limitation on Liability</h5>
            <p>THE COMPANY, ITS AFFILIATES, AND THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS WILL NOT BE HELD LIABLE FOR ANY DAMAGES, REGARDLESS OF LEGAL THEORY, ARISING FROM OR RELATED TO YOUR USE OR INABILITY TO USE THE WEBSITE, ANY LINKED WEBSITES, ANY CONTENT ON THE WEBSITE OR OTHER WEBSITES, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR OTHER WEBSITES. THESE DAMAGES INCLUDE DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, SUCH AS PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, OR LOSS OF DATA, WHETHER CAUSED BY NEGLIGENCE, BREACH OF CONTRACT, OR ANY OTHER REASON, EVEN IF FORESEEABLE. PLEASE NOTE THAT SOME STATES MAY NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.</p>

            <h5>17. Indemnification </h5>
            <p>By agreeing to these Terms of Use, you are accepting the responsibility to protect, compensate, and absolve the Company, its affiliates, licensors, and service providers, as well as their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from any claims, liabilities, damages, judgements, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) that may arise due to your violation of these Terms of Use or your use o The aforementioned obligations to provide compensation may not be legally enforceable in some states, hence these obligations may not be applicable to you.</p>

            <h5>18.  Governing Law and Jurisdiction</h5>
            <p>This Agreement will be subject to and interpreted in accordance with the laws of India, without considering its conflicts of law provisions. The interpretation of this Agreement should not be interpreted unfavourably towards the party that drafted it. You acknowledge and accept that any legal action or dispute arising from or relating to these Website Terms of Use will only be filed in the state courts located in India. Furthermore, you hereby agree without reservation to the exclusive jurisdiction of such courts over any lawsuit, legal action, or legal proceeding arising from these Website Terms of Use. IF EITHER PARTY WISHES TO BRING A DISPUTE AGAINST THE OTHER, IT MUST BE INITIATED WITHIN ONE YEAR AFTER THE DISPUTE ARISES, AS STATED IN SECTION 21. FAILURE TO DO SO WILL RESULT IN THE DISPUTE BEING PERMANENTLY BARRED. DISPUTES AND CLAIMS ARISING FROM THE TERMS OF SERVICE AND TERMS OF SALE WILL BE RESOLVED ACCORDING TO THE CORRESPONDING DISPUTE RESOLUTION PROVISIONS OUTLINED IN THOSE TERMS.</p>

            <h5>19. Waiver and Severability</h5>
            <p>Any waiver by the Company of a term or condition in these Terms of Use does not mean that the Company is waiving that term or condition in the future or any other term or condition. If the Company does not assert a right or provision under these Terms of Use, it does not mean that the Company is waiving that right or provision. If a court or other competent authority determines that any provision in these Terms of Use is invalid, illegal, or unenforceable, that provision will be removed or limited to the minimum extent necessary so that the remaining provisions of the Terms of Use remain in full force and effect.</p>

            <h5>20. Entire Agreement</h5>
            <p>The Terms of Use and Privacy Policy are the exclusive agreement between you and Corelens about the Website. They replace all previous and current understandings, agreements, representations, and warranties, whether written or spoken, regarding your use of the Website.</p>

            <p>The Terms of Service apply to your use of any products or services you have chosen to receive from Corelens, including account information and management. The Terms of Sale govern all purchases made through our Website or other transactions for the sale of products or services formed through the Website. These Terms of Use are in addition to any other applicable terms. If there are any conflicts between these Terms of Use and the Terms of Service or Terms of Sale, the Terms of Service or Terms of Sale will take precedence and govern the situation. <strong> Any assertions and conflicts originating from such shall be exclusively governed by those conditions and not these Terms of Use.</strong></p> 



        </div>
        <Footer/>
        </>
    )
}

export default TermUse