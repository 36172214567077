// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newpagination_btn1__kPi-m:disabled{
    color: #fff !important;
    background: rgb(194, 193, 193) !important;
    border-radius: 4px;
    padding-top:5px;
    padding: 8px !important;
    border: none;
    border-radius: 3px;
    display: flex;
    align-items: center;
}
.newpagination_btn2__J-eDd:disabled{
    color: white !important;
    background: rgb(194, 193, 193) !important;
    border-radius: 5px;
    /* padding:5px 6px; */
    padding: 0 13px;
    border: none;
    border-radius: 3px;
    display: flex;
    align-items: center;
}
.newpagination_btn1__kPi-m{
    padding-top:5px;
    background-color: black;
    color: white;
    padding: 8px ;
    border: none;
    border-radius: 3px;
    display: flex;
    align-items: center;

}
.newpagination_btn1__kPi-m > svg {
    font-size: 16px;
}
.newpagination_btn2__J-eDd > svg {
    font-size: 16px;
}
.newpagination_btn2__J-eDd{
    padding-top:5px;
    background-color: black;
    color: white;
    padding: 0 13px;
    border: none;
    border-radius: 3px;
    display: flex;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/NewPagination/newpagination.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,yCAAyC;IACzC,kBAAkB;IAClB,eAAe;IACf,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,uBAAuB;IACvB,yCAAyC;IACzC,kBAAkB;IAClB,qBAAqB;IACrB,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,uBAAuB;IACvB,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,mBAAmB;;AAEvB;AACA;IACI,eAAe;AACnB;AACA;IACI,eAAe;AACnB;AACA;IACI,eAAe;IACf,uBAAuB;IACvB,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".btn1:disabled{\n    color: #fff !important;\n    background: rgb(194, 193, 193) !important;\n    border-radius: 4px;\n    padding-top:5px;\n    padding: 8px !important;\n    border: none;\n    border-radius: 3px;\n    display: flex;\n    align-items: center;\n}\n.btn2:disabled{\n    color: white !important;\n    background: rgb(194, 193, 193) !important;\n    border-radius: 5px;\n    /* padding:5px 6px; */\n    padding: 0 13px;\n    border: none;\n    border-radius: 3px;\n    display: flex;\n    align-items: center;\n}\n.btn1{\n    padding-top:5px;\n    background-color: black;\n    color: white;\n    padding: 8px ;\n    border: none;\n    border-radius: 3px;\n    display: flex;\n    align-items: center;\n\n}\n.btn1 > svg {\n    font-size: 16px;\n}\n.btn2 > svg {\n    font-size: 16px;\n}\n.btn2{\n    padding-top:5px;\n    background-color: black;\n    color: white;\n    padding: 0 13px;\n    border: none;\n    border-radius: 3px;\n    display: flex;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn1": `newpagination_btn1__kPi-m`,
	"btn2": `newpagination_btn2__J-eDd`
};
export default ___CSS_LOADER_EXPORT___;
